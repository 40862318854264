import React, { useEffect, useState } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import {
  Datepicker,
  setOptions,
  localeFr,
  localeEn,
  localeDe,
} from "@mobiscroll/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { momentTimezone } from "@mobiscroll/react";
momentTimezone.moment = moment;

function HotelCalendar(props) {
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const { t } = useTranslation();
  const { min, max, valids, colors, handleChange } = props;

  useEffect(() => {
    setOptions({
      theme: "ios",
      themeVariant: "light",
      locale:
        selectedLang === "fr"
          ? localeFr
          : selectedLang === "en"
          ? localeEn
          : localeDe,
    });
  }, [selectedLang]);

  return (
    <div>
      <Datepicker
        // timezonePlugin={momentTimezone}
        // dataTimezone='utc'
        // displayTimezone='Europe/Berlin'
        display="inline"
        controls={["calendar"]}
        select="range"
        calendarType="month"
        showRangeLabels={true}
        inRangeInvalid={false}
        rangeEndInvalid={true}
        min={min}
        max={max}
        colors={colors}
        valid={valids}
        onChange={handleChange}
        dateFormat="YYYY-MM-DD"
        rangeStartLabel={t("check_in")}
        rangeEndLabel={t("check_out")}
        rangeStartHelp={t("choose_date")}
        rangeEndHelp={t("choose_date")}
        theme="my-theme"
        touchUi="auto"
        responsive={{
          xsmall: {
            display: "inline",
            pages: 1,
          },
          medium: {
            display: "inline",
            pages: 2,
          },
          large: {
            display: "inline",
            pages: 2,
          },
          custom: {
            breakpoint: 600,
            pages: 2,
          },
        }}
        className="w-full lg:min-w-[700px] xl:min-w-[900px] rounded shadow "
      />

      {colors?.length && (
        <div className="my-4 flex gap-2 items-center px-2">
          <div
            className="w-5 h-5 rounded-full"
            style={{ backgroundColor: colors[0].background }}
          ></div>
          <span className="text-xs md:text-sm mt-[3px]">
            {t("closing_date")}
          </span>
        </div>
      )}
        <p className="font-semibold p-2">
              {" "}
              {selectedLang === "fr"
                ? "Nous vous informons que notre établissement est fermé le dimanche et lundi. Notre restaurant est également fermé le mercredi au déjeuner mais vous accueille pour le dîner. "
                : selectedLang === "en"
                ? "Please note that Villa René Lalique is closed on Sunday and Monday. Our restaurant is also closed on Wednesday for lunch."
                : "Bitte beachten Sie, dass Villa René Lalique jeweils am Sonntag und Montag geschlossen ist. Unser Restaurant ist am Mittwoch Mittag ebenfalls geschlossen."}
        </p>
    </div>
  );
}

export default HotelCalendar;
