import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetchData from "../../hooks/useFetchData";
import { useEffect } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { addTable, setState } from "../../store/slices/restaurantSlice";
import RestaurantCalendar from "./RestaurantCalendar/RestaurantCalendar";
import WaitingListForm from "./WaitingListForm/WaitingListForm";
import { useTranslation } from "react-i18next";
import {
  resetState,
  setReservationType,
  setTotalAdults,
} from "../../store/slices/bookingParamSlice";
import { setHotelSettings } from "../../store/slices/settingsSlice";
import MenusDropdown from "../../components/MenusDropdown/MenusDropdown";
import { useLocation } from "react-router";
import { resetRestaurantState } from "../../store/slices/restaurantHotelSlice";

function RestaurantDates() {
  const [selectedAdult, setSelectedAdult] = useState(2);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMenu, setSelectedMenu] = useState({ name: "" });
  const [selectedService, setSelectedService] = useState("");
  const [comments, setComments] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [activeHourBtn, setActiveHourBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeBtn, setActiveBtn] = useState(selectedAdult - 1);
  const [menus, setMenus] = useState([]);
  const [services, setServices] = useState([]);
  const [closureDates, setClosureDates] = useState();
  const selectedLang = useSelector((state) => state.language.selectedLang);
  const [blockService, setBlockService] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: settingsData } = useFetchData(`/restaurant/settings`);
  const { data: hotelSettings } = useFetchData(`/hotel/settings`);
  const { data: restaurantData } = useFetchData(`/restaurant/availabilities/`);
  const { data: closingData } = useFetchData(`/company/closures/`);

  const { planning_start, planning_end } = hotelSettings;

  const location = useLocation();

  let specialDates = [
    "2023-12-25",
    "2023-12-26",
    "2024-03-29",
    "2024-03-31",
    "2024-04-01",
    "2024-05-09",
    "2024-05-19",
    "2024-05-20",
    "2024-07-14",
    "2024-08-15",
    "2024-11-01",
    "2024-11-10",
    "2024-11-11",
    "2024-12-25",
    "2024-12-26",
  ];

  useEffect(() => {
    blockHours();
  }, [selectedDate, menus]);

  useEffect(() => {
    window.addEventListener("popstate", handleResetState());
  }, [dispatch, location]);

  useEffect(() => {
    dispatch(setHotelSettings(hotelSettings));
  }, [hotelSettings, dispatch]);

  useEffect(() => {
    let arr = [];
    closingData.forEach((dates) => {
      if (dates.closure_type === "Restaurant") {
        arr.push(dates);
      }
      setClosureDates(arr);
    });
  }, [closingData]);

  useEffect(() => {
    if (selectedAdult && selectedDate) {
      axios
        .get(`/restaurant/availabilities/${selectedDate}/${selectedAdult}/R`)
        .then((data) => {
          if (
            (data.data.services[0].hours.length > 0 ||
              data.data.services[1].hours.length > 0) &&
            specialDates.includes(selectedDate)
          ) {
            let filteredMenus = [];
            filteredMenus = data.data.menus.filter(
              (menu) => menu.code !== "V3" && menu.code !== "INSP"
            );
            setServices(data.data.services);
            setMenus(filteredMenus);
          } else if (
            (data.data.services[0].hours.length > 0 ||
              data.data.services[1].hours.length > 0) &&
            !specialDates.includes(selectedDate)
          ) {
            setServices(data.data.services);
            setMenus(data.data.menus);
          }

          if (
            data.data.services[0].hours.length === 0 &&
            data.data.services[1].hours.length === 0
          ) {
            setServices({});
            setErrorMsg(`${t("table_not_found")}`);
          } else {
            setErrorMsg("");
          }
        })
        .catch((error) => console.log(error));
    }
  }, [selectedDate, selectedAdult]);

  const handleResetState = () => {
    sessionStorage.clear();
    dispatch(resetState());
    dispatch(resetRestaurantState());
    dispatch(setState());
  };

  const blockHours = () => {
    let today = new Date();
    let todayDate = today.toISOString().slice(0, 10);
    let todayHour = today.getHours();

    if (todayDate === selectedDate && todayHour >= 10) {
      setBlockService("lunch");
    } else if (todayDate === selectedDate && todayHour >= 16) {
      setBlockService("dinner");
    } else {
      setBlockService("");
    }
  };

  const handleAddTable = () => {
    const isHoraireInServices = services.some(service => service.hours.includes(selectedHour));
    if (isHoraireInServices){
      dispatch(
        addTable({
          date: selectedDate,
          service: selectedService,
          hours: selectedHour,
          adults: selectedAdult,
          children: 0,
          comments: comments,
          menu: selectedMenu.name,
          code: selectedMenu.code,
        })
      );
      dispatch(setReservationType("R"));
      dispatch(setTotalAdults(selectedAdult));
    }else{
      setErrorMsg(`${t("reservation_blocked")}`);
    }
    
  };

  const handleDate = (event) => {
    setSelectedHour("");
    setActiveHourBtn("");
    setServices("");
    setSelectedDate(event.valueText);
  };

  let dinnerMenus = [];
  let lunchMenus = [];

  specialDates.includes(selectedDate)
    ? menus.forEach((menu) => {
        dinnerMenus.push(menu);
        lunchMenus.push(menu);
      })
    : menus?.forEach((menu, i) => {
        menu?.available_on.forEach((m) => {
          if (
            m.weekday === new Date(selectedDate).getDay() &&
            m.service === "S"
          ) {
            dinnerMenus.push(menu);
          } else if (
            m.weekday === new Date(selectedDate).getDay() &&
            m.service === "M"
          ) {
            lunchMenus.push(menu);
          }
        });
      });

  return (
    <div
      className={`flex flex-col  w-full mb-44 border rounded-md col-span-2 bg-white`}
    >
      <div className="flex gap-2 justify-center sm:justify-start px-7 py-4 border-b flex-col">
        <div className="flex gap-2 border-b flex-col mb-6">
          <div className="text-sm italic">
            <p className="font-semibold mb-5">{t("restaurant_group_msg")}</p>
            <p className="font-semibold p-2">
              {" "}
              {selectedLang === "fr"
                ? "Nous vous informons que notre établissement est fermé le dimanche et lundi. Notre restaurant est également fermé le mercredi au déjeuner mais vous accueille pour le dîner. "
                : selectedLang === "en"
                ? "Please note that Villa René Lalique is closed on Sunday and Monday. Our restaurant is also closed on Wednesday for lunch."
                : "Bitte beachten Sie, dass Villa René Lalique jeweils am Sonntag und Montag geschlossen ist. Unser Restaurant ist am Mittwoch Mittag ebenfalls geschlossen."}
        </p>

          </div>
        </div>

        <p className="font-semibold text-lg">
          {t("chose_nb_guest")} <span className="text-red-500">*</span>
        </p>
        <div className="flex flex-row flex-wrap">
          {Array(settingsData.max_guests_per_table)
            .fill(null)
            .map((el, i) => (
              <button
                className={`border w-12 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                  activeBtn === i && "bg-primary text-white font-semibold"
                }`}
                key={i}
                onClick={() => {
                  setSelectedAdult(i + 1);
                  setActiveBtn(i);
                }}
              >
                {i + 1}
              </button>
            ))}
        </div>
        <span className="text-red-600 text-sm">{errorMsg}</span>
      </div>
      <div className="flex gap-2 justify-center sm:justify-start px-7 py-4 border-b bg-white  flex-col">
        <p className="max-w:fit font-semibold text-lg">
          {t("chose_date")} <span className="text-red-500">*</span>
        </p>
        <div className="flex flex-col lg:flex-row ">
          <RestaurantCalendar
            handleDate={handleDate}
            restaurantData={restaurantData}
            colors={closureDates}
            min={planning_start}
            max={planning_end}
          />
          <div className="">
            {/* ================== HEURE DEJEUNER =============*/}
            {selectedAdult > 0 && selectedDate.length > 0 && !errorMsg && (
              <div className="max-lg:mt-4 lg:px-5 flex flex-col">
                <div>
                  <p className="pb-2 font-semibold text-lg">
                    {t("chose_time")} <span className="text-red-500">*</span>
                  </p>
                  <div className="flex flex-col gap-5 mt-2">
                    {blockService !== "lunch" && services[0]?.hours.length > 0 && (
                      <div>
                        <span className="italic underline">{t("lunch")}</span>
                        <div className="flex gap-2 flex-wrap  mt-2">
                          {services[0]?.hours.map((hour, i) => (
                            <button
                              className={`border w-16 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                                activeHourBtn === hour &&
                                "bg-primary text-white font-semibold"
                              }`}
                              key={i}
                              onClick={() => {
                                setSelectedHour(hour);
                                setActiveHourBtn(hour);
                                setSelectedService("M");
                              }}
                            >
                              {hour}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* ================== HEURE DINER =============*/}
                    {blockService !== "dinner" &&
                      services[1]?.hours.length > 0 && (
                        <div>
                          <span className="italic underline">
                            {t("dinner")}
                          </span>
                          <div className="flex gap-2 flex-wrap mt-2">
                            {services[1]?.hours.map((hour, i) => (
                              <button
                                className={`border w-16 h-8 rounded-md mr-2 hover:bg-primary duration-300 font-semibold ${
                                  activeHourBtn === hour &&
                                  "bg-primary text-white font-semibold"
                                }`}
                                key={i}
                                onClick={() => {
                                  setSelectedHour(hour);
                                  setActiveHourBtn(hour);
                                  setSelectedService("S");
                                }}
                              >
                                {hour}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}

            <div className="bg-slate-100 border rounded-md p-2 mt-5 lg:mx-5 flex flex-col items-center">
              <h4 className="font-semibold">{t("waiting_list_title")}</h4>
              <p>{t("waiting_list_msg")}</p>
              <WaitingListForm
                settingsData={settingsData}
                restaurantData={restaurantData}
                selectedDate={selectedDate}
                selectedAdult={selectedAdult}
              />
            </div>
          </div>
        </div>
      </div>

      {/* ================== MENUS Déjeuner =============*/}

      {selectedService === "M" && (
        <div className="bg-white py-5 px-5 flex gap-5 flex-col md:flex-row md:items-end">
          <div>
            <p className="pb-2 font-semibold text-lg">{t("chose_menu")}</p>
            <MenusDropdown
              options={lunchMenus}
              value={selectedMenu}
              onChange={(o) => setSelectedMenu(o)}
              selectedDate={selectedDate}
            />
          </div>

          <div>
            {lunchMenus?.map((menu, i) => {
              if (menu.name === selectedMenu.name) {
                return (
                  <a
                    href={`${
                      selectedLang === "fr"
                        ? menu.description_fr
                        : selectedLang === "en"
                        ? menu.description_en
                        : menu.description_de
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    key={i}
                    className="underline hover:text-primary duration-300"
                  >
                    {" "}
                    {`${t("see_menu")} "${menu.name}"`}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="ml-2"
                    />
                  </a>
                );
              }
            })}
          </div>
        </div>
      )}

      {/* ================== MENUS Dîner =============*/}
      {selectedService === "S" ? (
        <div className="bg-white  py-5 px-5 flex gap-5 flex-col md:flex-row md:items-end">
          <div>
            <p className="pb-2 font-semibold text-lg">{t("chose_menu")}</p>

            <MenusDropdown
              options={dinnerMenus}
              value={selectedMenu}
              onChange={(o) => setSelectedMenu(o)}
              selectedDate={selectedDate}
            />
          </div>

          <div>
            {dinnerMenus?.map((menu, i) => {
              if (menu.name === selectedMenu.name) {
                return (
                  <a
                    href={`${
                      selectedLang === "fr"
                        ? menu.description_fr
                        : selectedLang === "en"
                        ? menu.description_en
                        : menu.description_de
                    }`}
                    target="_blank"
                    key={i}
                    className="underline "
                    rel="noreferrer"
                  >
                    {" "}
                    {`${t("see_menu")} "${menu.name}"`}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="ml-2"
                    />
                  </a>
                );
              }
            })}
          </div>
        </div>
      ) : null}

      <div className=" rounded p-1 w-full flex flex-col px-5 bg-white mb-3 border-t pt-4">
        <label className="pb-2 text-lg font-semibold">{t("comments")}</label>
        <textarea
          placeholder={t("comments_placeholder_resto")}
          type="text"
          onChange={(e) => setComments(e.target.value)}
          className="border rounded p-2 text-sm"
        />
      </div>

      <button
        className={`bg-secondary hover:bg-primary rounded text-white duration-300 text-lg py-2 px-4 mt-3 my-5 self-center w-1/2  focus:outline-none focus:ring-1 focus:ring-black ${
          selectedHour.length < 1 ? "pointer-events-none !bg-gray-400" : ""
        }`}
        onClick={handleAddTable}
      >
        {t("validate")}
      </button>
    </div>
  );
}

export default RestaurantDates;
