import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";

function Footer() {
  const appSettings = useSelector((state) => state.settings.appSettings);

  return (
    <div className="bg-black text-white text-center md:items-end flex flex-col justify-center p-5 gap-1 md:justify-between md:flex-row-reverse min-h-[10vh] fixed bottom-0 w-full z-10">
      <a
        target="_blank"
        href={appSettings.website_link}
        className="hover:text-primary"
      >
        Site Web Villa René Lalique
        <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-1" />
      </a>

      <div className="flex max-md:flex-col">
        <p className="opacity-80">Copyright 2023 Villa René Lalique</p>
        <span className="px-1 max-md:hidden">|</span>

        <a
          target="_blank"
          href={appSettings.cgv_link}
          className="hover:text-primary"
        >
          Conditions Générales de Ventes
        </a>
      </div>
    </div>
  );
}

export default Footer;
